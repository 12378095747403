<!--
 * @Author: lijun
 * @Date: 2021-11-06 16:00:27
 * @LastEditTime: 2022-08-22 14:26:31
 * @LastEditors: ch3nh2
 * @Description: 消息调度-新消息
 * @FilePath: /zhy_dataview_fe/src/pages/NoticeSchedule/Details.vue
-->
<template>
  <div class="notice_container">
    <div class="arrow_card">
      <b class="b top_left"></b><b class="b top_right"></b><b class="b bottom_left"></b><b class="b bottom_right"></b>
      <div class="row-container">
        <el-row :gutter="10">
          <el-col :span="12">
            <div class="card_title">消息详情</div>
            <div class="card_content">
              <el-form ref="ruleForm" :model="ruleForm" class="rule-form">
                <el-form-item label="消息标题" prop="title">
                  <el-input readonly v-model="ruleForm.title" maxlength="100"></el-input>
                </el-form-item>
                <div class="content">
                  <div class="title">消息正文</div>
                  <RichText :readOnly="true" :importTxt="ruleForm.content" />
                </div>
                <div class="file-list">
                  <div class="item-title">
                    附件
                  </div>
                  <ul class="list">
                    <li v-if="ruleForm.attach">
                      <el-icon>
                        <Paperclip />
                      </el-icon>
                      <div class="file-name">{{ ruleForm.attach }}</div>
                      <a @click="downloadFileEvent(ruleForm.attach)">下载附件</a>
                    </li>
                  </ul>
                  <div style="color: #999" v-if="!ruleForm.attach">
                    -- 无附件 --
                  </div>
                </div>
                <div class="send-time">
                  <div class="item-title">发送时间</div>
                  <el-input style="width: 330px" readonly v-model="ruleForm.sendTime" maxlength="100"></el-input>
                </div>
              </el-form>
            </div>
          </el-col>

          <el-col :span="12">
            <div class="card_title">接收对象</div>
            <div class="card_content">
              <el-row :gutter="10">
                <el-col :span="11">
                  <PeopleGroupList :hasDelPeopleBtn="false" :recipientUserIds="recipientUserList" />
                  <div class="action">
                    <el-button type="primary" v-if="status === 0" @click="dialogVisible = true">撤销发布</el-button>
                    <el-button @click="backEvent">返回</el-button>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-dialog v-model="dialogVisible" title="提示" width="500px" center custom-class="dialog">
      <div class="dialog-content" align="center">
        <el-icon>
          <WarningFilled style="color: #f05858" />
        </el-icon>
        <h3>确认取消发布？</h3>
      </div>
      <template #footer>
        <span class="dialog-action">
          <el-button type="primary" @click="cancelReleaseEvent">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  Plus,
  Switch,
  CircleCheckFilled,
  WarningFilled,
  Paperclip,
} from "@element-plus/icons";
import PeopleGroupList from "components/InsidePages/PeopleGroupList";
import RichText from "./components/rich-text";
import utils from "@/utils/index";

export default {
  name: "noticeDetails",
  data() {
    return {
      urlId: "",
      ruleForm: {
        title: "",
        content: "",
        sendTime: "",
        attach: "",
      },
      recipientUserList: [],
      // 状态: 0-未发送, 1-已发送, 2-已取消
      status: 1,
      dialogVisible: false,
    };
  },
  mounted() {
    const { query } = this.$route;
    this.urlId = query.id;
    this.getNoticeDetailsEvent();
  },
  methods: {
    // 下载文件事件
    downloadFileEvent(fileName) {
      this.$api
        .downloadFile(
          { fileName },
          { responseType: "blob" }
        )
        .then((res) => {
          utils.blobTransition(res, fileName);
        });
    },
    // 获取消息详情
    getNoticeDetailsEvent() {
      this.$api
        .getNoticeInfo({
          id: this.urlId,
        })
        .then((res) => {
          const { data } = res;
          const {
            title,
            content,
            sendTime,
            attach,
            status,
            recipientUserList,
          } = data;
          this.ruleForm = {
            title,
            content,
            sendTime: sendTime ? dayjs(sendTime).format("YYYY-MM-DD HH:mm") : '无',
            attach,
          };
          this.status = status;
          this.recipientUserList = recipientUserList;
        });
    },
    // 撤销发布
    cancelReleaseEvent() {
      this.dialogVisible = false;
      this.$api
        .cancelNotice({
          id: this.urlId,
        })
        .then((res) => {
          const { code } = res;
          if (code === "B200") {
            this.backEvent();
          }
        });
    },
    // 返回到列表
    backEvent() {
      this.$router.push("/notice/initiate");
    },
  },
  components: {
    Plus,
    Switch,
    CircleCheckFilled,
    WarningFilled,
    Paperclip,
    PeopleGroupList,
    RichText,
  },
};
</script>

<style lang="scss" scoped>
.notice_container {
  padding: 20px 0;
}

.row-container {
  padding: 12px 0;
}

.arrow_card {
  width: 100%;
  height: 900px;
  background: #202331;

  .card_title {
    margin-bottom: 20px;
    font-size: 18px;
  }
}

.search-input {
  width: 400px;
  margin-right: 40px;
}

.rule-form {
  padding: 0 15px;

  .avatar-uploader-box {
    margin: 10px;
  }

  .avatar-uploader-icon {
    width: 80px;
    height: 80px;
  }
}

.switch-icon {
  display: flex;
  height: 100%;
  vertical-align: middle;
  align-items: center;
  justify-content: center;

  .el-icon {
    font-size: 26px;
    font-weight: bold;
    color: #4d4f5a;
  }
}

.action {
  display: flex;

  .el-button {
    width: 170px;
    letter-spacing: 4px;
    font-size: 18px;
    margin-right: 70px;
  }
}

.pagination {
  margin-top: 15px;
}

.item-title {
  font-size: 18px;
  margin-bottom: 20px;
}

.file-list {
  color: #fff;
  margin: 40px 0;

  .list {
    li {
      display: flex;
      align-items: center;

      i,
      .file-name {
        margin-right: 20px;
      }

      a {
        text-decoration: none;
        color: #f05858;
        cursor: pointer;
        user-select: none;
      }
    }
  }
}

.send-time {
  color: #fff;
}

.card_content {
  .content {
    margin-bottom: 20px;
    padding-top: 5px;

    .title {
      font-size: 18px;
      color: #fff;
      margin-bottom: 12px;
    }
  }
}
</style>
